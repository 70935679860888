import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'

// Components
import Layout from '../components/Layout'
import {
  CopyStyle,
  HeaderStyle,
} from '../components/UI'
import CallAddress from '../components/CallAddress'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import HubSpotContactForm from '../components/HubSpotContactForm'

const Contact = ({ location, data }) => {
  const pageData = data.contentfulContactPage
  console.log('pageData.ctaImage', pageData.ctaImage)

  return (
    <Layout location={location}>
      <Seo
        title={pageData.metadataTitle || 'Contact'}
        description={pageData.metadataDescription}
        image={
          pageData.metadataImage
            ? `https:${pageData.metadataImage.file.url}`
            : undefined
        }
      />
      {/* Hero */}
      <section tw="bg-[#E9F1F3]">
        {/* <div tw="max-w-screen-xl mx-auto"> */}
        <div tw="flex flex-col lg:(grid grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr] relative h-[369px])">
          {/* Image */}
          <div tw="order-1 h-48 relative overflow-x-hidden lg:(order-2 col-start-8 col-span-7 h-full)">
            <GatsbyImage
              objectFit="cover"
              tw="w-full lg:(h-full absolute top-0)"
              image={pageData.heroImage.gatsbyImageData}
              alt={pageData.heroImage.filename}
            />
          </div>
          {/* Content */}
          <div tw="order-2 mx-7 my-12 lg:(order-1 flex flex-col justify-center mx-0 mb-0 col-start-2 col-span-5 my-0)">
            <h1 css={[HeaderStyle, tw`mb-6`]}>{pageData.heroHeadline}</h1>
            <p css={[CopyStyle, tw`max-h-full`]}>
              {pageData.heroSubhead.heroSubhead}
            </p>
          </div>
        </div>
        {/* </div> */}
      </section>

      {/* Form */}
      <section tw="grid grid-cols-4 gap-6 mx-7 py-14 lg:(max-w-screen-lg grid-cols-12 mx-auto)">
        <HubSpotContactForm />
      </section>

      {/* CTA */}
      <CallAddress
        header={`Staffy Health Address`}
        image={pageData.ctaImage}
      />
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  {
    contentfulContactPage(pageId: { eq: "Contact Page" }) {
      heroHeadline
      heroSubhead {
        heroSubhead
      }
      heroImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
      ctaHeadline
      ctaSubhead {
        ctaSubhead
      }
      ctaImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1024
          quality: 80
        )
      }
      metadataTitle
      metadataDescription
      metadataImage {
        file {
          url
        }
      }
    }
  }
`
