import React from 'react';
import tw from 'twin.macro';

const Spinner = ({ size = 30, color, containerStyles }) => {
  return (
    <div css={[
      tw`flex justify-center items-center`,
      containerStyles
    ]}>
      <div css={[
        tw`animate-spin rounded-full border-t-2 border-b-2`,
        { width: size, height: size, borderColor: color || '#FF4D00' },
      ]}></div>
    </div>
  );
};

export default Spinner;
